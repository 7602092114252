import React from 'react';
import './ChoiceButtons.css';

class ChoiceButtons extends React.Component {
    constructor(props){
        super(props);


        this.handleStronglyAgree = this.handleStronglyAgree.bind(this);
        this.handleAgree = this.handleAgree.bind(this);
        this.handleNeutral = this.handleNeutral.bind(this);
        this.handleDisagree = this.handleDisagree.bind(this);
        this.handleStronglyDisagree = this.handleStronglyDisagree.bind(this);
    }

    handleStronglyAgree() {
        this.props.handleAnswer(5);

    }

    handleAgree(){
        this.props.handleAnswer(2.5);
    }

    handleNeutral() {
        this.props.handleAnswer(0);

    }

    handleDisagree(){
        this.props.handleAnswer(-2.5);
    }

    handleStronglyDisagree() {
        this.props.handleAnswer(-5);

    }

    render() {
        return (
            <div className="choiceButtonWrapper">
                <button className='choiceButton' onClick={this.handleStronglyDisagree}>Struggling</button>
                <button className='choiceButton' onClick={this.handleDisagree}>Needs Work</button>
                <button className='choiceButton' onClick={this.handleNeutral}>Getting By</button>
                <button className='choiceButton' onClick={this.handleAgree}>Pretty Good</button>
                <button className='choiceButton' onClick={this.handleStronglyAgree}>Amazing</button>

            </div>
        )
    }
}

export default ChoiceButtons;