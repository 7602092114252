const categories = [
  {
    name: "Purpose",
    description:
      "The purpose of your organization, your “why,” often emanates from deep within. Powerful brands align who the company is at its core with how it is perceived in the marketplace. In this sense, the personality of a brand is byproduct of its culture. Imagine Disney saying it’s “The happiest place on Earth” and then having a customer experience to the contrary. When people believe in the purpose of the organization they are unstoppable.",
  },
  {
    name: "People",
    description:
      "As organizations grow they generate more complexity. Complexity is the enemy of growth because it can hinder communication, learning and strategic clarity. Entrepreneurial teams may require new skillsets, disciplines and frameworks to order to break through a complex growth ceiling. Owners, leaders, teams and individuals must learn to think and act differently if they hope to unlock their potential as key contributors to organizational success.",
  },
  {
    name: "Process",
    description:
      "Reliable products and performances arise when clear and repeatable processes drive quality and service delivery. Without process even the most talented teams struggle to perform. Key processes protect the brand, enhance margin and drive quality. Growth, brand equity and customer loyalty are derivatives of promises kept and process, at its core, is about keeping the promises you make.",
  },
  {
    name: "Place",
    description:
      " Our physical spaces can reinforce our organizational and personal identities. We surround ourselves with the artifacts that remind us of who we are, from where we have come and where we are going. Space provides a tangible sense of purpose and meaning. When physical space aligns to reinforce the values, history and story of an organization its emboldens the organization’s intention and purpose.",
  },
  {
    name: "Leadership",
    description: "Research tells us that leadership is 40-70% responsible for creating a productive and positive work environment. Leaders shape and mold behavior, coach and support success and translates the Purpose of the organization into action. Leadership skill provides a foundation of certainty and a clearer focus of the future. Never underestimate the power of having purpose-driven leaders at the table.",
  },
  {
    name: "Performance",
    description: "It is impossible to know if your people are winning without a system for measuring success. This means regular feedback in an objective, trust based environment. Great leaders understand the important role they play in measuring the contribution of their team members. Aligning this contribution within the processes that drive and support your growth is key to business success and higher employee engagement.",
  },
  {
    name: "System",
    description: "A system is an organized or established procedure that provides a result or outcome. In business systems can take many forms. Operating systems that help teams work more effectively together. Enterprise systems that help makes sense of data and process. Financial systems that provide key insights into revenue, margin and profit. And many others. Systems are often designed to function within a physical context operated by skilled people who provide the desired result. Systems can help protect service or product quality, customer loyalty, delivery times, margin and profit and growth. Effective systems take into consideration not only the Processes they support but the Places in which they operate.",
  },
  {
    name: "Setting",
    description: "The physical environment where a product is produced or service is delivered is known as its Setting. Settings can both positively and negatively impact your work product. For instance, many people have recently experienced that a remote work setting is very different than an onsite one. Both can be effective but each requires very different considerations. Settings provide a physical link between the Purpose of the organization and Place where the work is done. Setting reinforce the brand promise and provide the construct for delivery. Think of your Place as the “stage” on which you build a “set” that tells a story (Purpose) supported by Systems (lighting, sound, physical plants, etc.) that delivers a performance.",
  },
  {
    name: "Climate",
    description: "Climate represents the thoughts, feelings, attitudes and behaviors of the People within the organization. It is shaped by the Purpose, Place and People of the organization. Climate’s organizational influence is one of Leadership’s strongest tools to nurture and shape a desirable Culture. The Climate should reinforce the organization’s beliefs (Core Values) in words, actions and artifacts it adopts.",
  },
  {
    name: "Hiring",
    description: "The importance of having the right people in the right seats cannot be overstated. When People are out of sync with the organization’s values or lack the ability to perform the work required, bad things can happen. Toxic behavior, low employee morale, power performance and dysfunctional team are just a few outcomes of poor hiring practice. When the “right people are on the bus” businesses thrive.",
  },
  {
    name: "Skills",
    description: "Creating a learning environment where People adopt new skills and abilities is key to your organization's continued growth and success. Not only do employees become more valuable as contributing members of the team, but you reinforce a psychologically safe environment where People are more inclined to innovate, take risks and act more confidently. With new skills comes new ideas, thinking and abilities.",
  },
  {
    name: "Tools",
    description: "“When you only have a hammer…” Having the right tools for the job is critical to driving efficiency and performance within the systems on which you rely. Providing a well-trained team with the right tools can provide dramatic improvements in quality, speed and delivery. Remember what Steven Jobs said, “You cannot mandate productivity, provide the tools to let people become their best.”",
  },
];

export default categories;
