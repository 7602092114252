import React from 'react';
import './BackButton.css';

class BackButton extends React.Component {
    constructor(props){
        super(props);
        this.handleBack = this.handleBack.bind(this);
    }

    handleBack() {
        this.props.handleBack();
    }

    render() {
        return (
            <img id="backButton" onClick={this.handleBack} src='https://static.thenounproject.com/png/1991936-200.png'/>
        )
    }
}

export default BackButton;