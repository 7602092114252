import React from "react";
import "./App.css";

//firebase
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/functions";

import Question from "../Question/question";
import QuestionNumbers from "../QuestionNumbers/QuestionNumbers";
import ChoiceButtons from "../ChoiceButtons/ChoiceButtons";
import BackButton from "../BackButton/BackButton";
// import EndScores from "../EndScores/EndScores";
import VennDiagram from "../VennDiagram/VennDiagram";
import FacilitatorPanel from '../FacilitatorPanel/FacilitatorPanel';


import questions from "../../util/questions";
// import categories from '../../util/categories';

const firebaseConfig = {
  apiKey: "AIzaSyC87G5p3EF-E3VGbeurXqa_jkc3jQktijI",
  authDomain: "stitch-assessment-18000.firebaseapp.com",
  databaseURL: "https://stitch-assessment-18000.firebaseio.com",
  projectId: "stitch-assessment-18000",
  storageBucket: "stitch-assessment-18000.appspot.com",
  messagingSenderId: "1036387220380",
  appId: "1:1036387220380:web:eec4e430a574c38a1fa659",
  measurementId: "G-BTXZHX9JKJ",
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

class App extends React.Component {
  constructor(props) {
    super(props);

    let defaultScores = [];
    for (let i = 0; i < questions.length; i++) {
      defaultScores.push({
        prompt: questions[i].prompt,
        category: questions[i].category,
        score: 0,
      });
    }

    this.state = {
      currentQuestionNumber: 0,
      currentQuestionPrompt: "",
      currentCategory: "",
      scores: defaultScores,
      finalScores: [],
      phase: "start",
      name: "test3",
      email: "test",
      company: "test",
      tooltipCategory: '',
      tooltipDetails: '',
      login: false,
      facilitatorPanel: false
    };

    

    this.nextQuestion = this.nextQuestion.bind(this);
    this.previousQuestion = this.previousQuestion.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.startQuiz = this.startQuiz.bind(this);
    this.endQuiz = this.endQuiz.bind(this);
    this.tallyScores = this.tallyScores.bind(this);
    this.postResult = this.postResult.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateTooltip = this.updateTooltip.bind(this);
    this.login = this.login.bind(this);
  }

  login() {
    this.setState({login: true})
  }

  handleSubmit() {
    this.postResult();
  }

  postResult() {
    console.log("post result");
    const nameInput = document.getElementById("name").value;
    const emailInput = document.getElementById("email").value;
    const companyInput = document.getElementById("company").value;
    const codeInput = document.getElementById('code').value;
    if (nameInput && emailInput && companyInput) {
      db.collection("results")
        .doc()
        .set({
          name: nameInput,
          email: emailInput,
          company: companyInput.toUpperCase(),
          code: codeInput.toUpperCase(),
          Purpose: this.state.finalScores[0].score,
          People: this.state.finalScores[1].score,
          Process: this.state.finalScores[2].score,
          Place: this.state.finalScores[3].score,
          Leadership: this.state.finalScores[4].score,
          Performance: this.state.finalScores[5].score,
          System: this.state.finalScores[6].score,
          Setting: this.state.finalScores[7].score,
          Climate: this.state.finalScores[8].score,
          Hiring: this.state.finalScores[9].score,
          Skills: this.state.finalScores[10].score,
          Tools: this.state.finalScores[11].score,
          Answers: this.state.scores,
        })
        .then(() => {
          console.log("Results successfully added.");
          document.getElementById("name").value = null;
          document.getElementById("email").value = null;
          document.getElementById("company").value = null;
          document.getElementById('code').value = null;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("All fields are required to sumbit.");
    }
  }

  nextQuestion() {
    this.setState({
      currentQuestionNumber: this.state.currentQuestionNumber + 1,
    });
    this.setState({
      currentQuestionPrompt:
        questions[this.state.currentQuestionNumber + 1].prompt,
    });
  }

  previousQuestion() {
    if (this.state.currentQuestionNumber === 0) {
      return;
    }
    this.setState({
      currentQuestionNumber: this.state.currentQuestionNumber - 1,
    });
    this.setState({
      currentQuestionPrompt:
        questions[this.state.currentQuestionNumber - 1].prompt,
    });
  }

  handleAnswer(score) {
    let currentScores = this.state.scores;
    currentScores[this.state.currentQuestionNumber].score = score;
    if (this.state.currentQuestionNumber === questions.length - 1) {
      this.endQuiz();
    } else {
      this.nextQuestion();
    }
  }

  startQuiz() {
    this.setState({ phase: "quiz" });
    this.setState({ currentQuestionNumber: 0 });
    this.setState({ currentQuestionPrompt: questions[0].prompt });
  }

  endQuiz() {
    this.setState({ phase: "end" });
    this.tallyScores();
  }

  tallyScores() {
    let finalScores = [];

    let purposeScore = this.state.scores
      .filter((question) => question.category === "purpose")
      .map((question) => question.score)
      .reduce((a, b) => a + b);
    finalScores.push({ category: "purpose", score: purposeScore });

    let peopleScore = this.state.scores
      .filter((question) => question.category === "people")
      .map((question) => question.score)
      .reduce((a, b) => a + b);
    finalScores.push({ category: "people", score: peopleScore });

    let processScore = this.state.scores
      .filter((question) => question.category === "process")
      .map((question) => question.score)
      .reduce((a, b) => a + b);
    finalScores.push({ category: "process", score: processScore });

    let placeScore = this.state.scores
      .filter((question) => question.category === "place")
      .map((question) => question.score)
      .reduce((a, b) => a + b);
    finalScores.push({ category: "place", score: placeScore });

    let leadershipScore = (purposeScore + peopleScore) / 2;
    finalScores.push({ category: "leadership", score: leadershipScore });
    let performanceScore = (peopleScore + processScore) / 2;
    finalScores.push({ category: "performance", score: performanceScore });
    let systemScore = (processScore + placeScore) / 2;
    finalScores.push({ category: "system", score: systemScore });
    let settingScore = (placeScore + purposeScore) / 2;
    finalScores.push({ category: "setting", score: settingScore });

    let climateScore = (settingScore + leadershipScore) / 2;
    finalScores.push({ category: "climate", score: climateScore });
    let hiringScore = (leadershipScore + performanceScore) / 2;
    finalScores.push({ category: "hiring", score: hiringScore });
    let skillsScore = (performanceScore + systemScore) / 2;
    finalScores.push({ category: "skills", score: skillsScore });
    let toolsScore = (systemScore + settingScore) / 2;
    finalScores.push({ category: "tools", score: toolsScore });

    this.setState({ finalScores: finalScores });
  }

  updateTooltip(categoryName, categoryDetails) {
    this.setState({tooltipCategory: categoryName, tooltipDetails: categoryDetails})
}

componentDidMount() {
  document.addEventListener('keydown', event => {
    if (event.key === 'F'){
      this.setState({facilitatorPanel: true})
    }
  }
    
    )
}

  render() {
    const phase = this.state.phase;
    let content;
    switch (phase) {
      case "start":
        content = (
          <div>
          {this.state.facilitatorPanel === true && <FacilitatorPanel database={db}/>}
          <div className="startPhase">
            <h1 className="light center">Stitch Assessment</h1>
            <button id="startButton" onClick={this.startQuiz}>
              Begin the Assessment
            </button>
            <h1>{this.state.test}</h1>
          </div>
          </div>
        );
        break;
      case "quiz":
        content = (
          <div className="questionPhase">
            
            <BackButton handleBack={this.previousQuestion} />
            <QuestionNumbers
              currentQuestionNumber={this.state.currentQuestionNumber}
            />
            <Question question={this.state.currentQuestionPrompt} />

            <ChoiceButtons handleAnswer={this.handleAnswer} />
          </div>
        );
        break;
      case "end":
        content = (
          <div className="endPhase">
           
            <div id="vennContainer">
              <VennDiagram finalScores={this.state.finalScores} updateTooltip={this.updateTooltip}/>
              <div id="tooltip">
                <h3 id="categoryName">{this.state.tooltipCategory || 'Hover for Details'}</h3>
                <p id="categoryDetails" className='body'>{this.state.tooltipDetails || 'Hover or click on the image to learn more. Red areas show weakness in that area, green areas show strength.'}</p>
              </div>
            </div>
            {/* <EndScores finalScores={this.state.finalScores} /> */}
            <div id="thankYouMessage">
              <h3 className="light center">
              Thank you for filling out the assessment.
            </h3>
            <p className="light center">
              Enter your information below for a free, comprehensive analysis of
              your results.
            </p>
            </div>
            <form id="endForm">
              <input id="name" type="text" placeholder="Name"></input>
              <input id="email" type="email" placeholder="Email"></input>
              <input id="company" type="text" placeholder="Company"></input>
              <input id="code" type="text" placeholder="Code (optional)"></input>
            </form>
            <button id="submitButton" onClick={this.handleSubmit}>
              Submit
            </button>
          </div>
        );
        break;
      default:
        break;
    }

    return content;
  }
}

export default App;
