import React from "react";
import "./FacSearch.css";

class FacSearch extends React.Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
  }

  search() {
    let searchCompany = document.getElementById("searchCompany").value;
    let searchCode = document.getElementById('searchCode').value;

    if (searchCode){
      this.props.search({type: 'code', term: searchCode});
    } else {
      this.props.search({type: 'company', term: searchCompany});
    }
    
  }

  render() {
    return (
      <div id="searchWrapper">
          <h1>Enter a company name or code below for aggregated assessment scores.</h1>
          <div id="inputWrapper">
        <input type="text" id="searchCompany" placeholder="Company" />
        <input type="text" id="searchCode" placeholder="Code" />
        </div>
        <button id="FacSearch" onClick={() => this.search()}>
          Search
        </button>
      </div>
    );
  }
}

export default FacSearch;
