import React from 'react';
import './FacilitatorPanel.css';

import FacSearch from '../FacSearch/FacSearch';
import FacResults from '../FacResults/FacResults';


class FacilitatorPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchResults: []


        }

        this.handleSearch = this.handleSearch.bind(this);
    }

    handleSearch(term){
        console.log(term.term);
        console.log(this.props.database);
        let searchResults = [];
        const db = this.props.database;
        if(term.type === 'company'){
        db.collection('results')
        .where('company', '==', term.term.toUpperCase())
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                searchResults.push(doc.data());
                console.log(doc.id, ' => ', doc.data());
                
            })
        })
        .then(() => {
            this.setState({searchResults: searchResults})

        })
        .catch(error => {
            console.log(error)
        })
    } else {
        db.collection('results')
        .where('code', '==', term.term.toUpperCase())
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                searchResults.push(doc.data());
                console.log(doc.id, ' => ', doc.data());
                
            })
        })
        .then(() => {
            this.setState({searchResults: searchResults})

        })
        .catch(error => {
            console.log(error)
        })
    }
    }

    render() {
        return(
            <div id="mainPanel">
                <FacSearch search={this.handleSearch} />
                <FacResults results={this.state.searchResults}/>

            </div>
        )
    }

}

export default FacilitatorPanel;