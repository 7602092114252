import React from 'react';
import './FacResults.css';

import FacSubmission from '../FacSubmission/FacSubmission';

class FacResults extends React.Component {
    constructor(props){
        super(props);
        this.getAverage = this.getAverage.bind(this);
    }

    getAverage(results) {
        let averages = {};
        let categories = Object.keys(results[0]).filter(x => (x !== 'name' && x !== 'email' && x !== 'company' && x !== 'code' && x !== 'Answers'));
        console.log(categories);
        
        averages.name = 'Average';
        averages.company = results[0].company

        categories.forEach(cat => {
            let average = results.map(result => result[cat]).reduce((a, b) => a + b) / results.length
            averages[cat] = average;
        }
            )
        console.log(averages)
        return averages;
    }


    render() {
        return (
            <div className="resultsList">
               {this.props.results.length > 1 && <FacSubmission result={this.getAverage(this.props.results)} id="average"/>}
    
            {this.props.results.map(result => {
               return <FacSubmission result={result} />
            })
        }
      </div>
        )
    }
}

export default FacResults;