const questions = [
    // Purpose Questions
    {
        prompt: "The beliefs and behaviors of our organization (Core Values) are clearly defined.",
        category: "purpose",
        },
    {
        prompt: "We understand what motivates people about working for our company.",
        category: "purpose",
        },
    {
        prompt: "People get excited when they talk about what we do and why we do it.",
        category: "purpose",
        },
    {
        prompt: "Our brand promise is clearly differentiated in the market against our competitors.",
        category: "purpose",
        },
    {
        prompt: "Our purpose guides us, informs our decisions and inspires our actions.",
        category: "purpose",
        },
    //People Questions
    {
        prompt: "Our People can easily describe the greater purpose our company serves.",
        category: "people",
        },
    {
        prompt: "People exhibit Core Value behaviors in their words and actions.",
        category: "people",
        },
    {
        prompt: "Everyone understands the key accountabilities associated with their job.",
        category: "people",
        },
    {
        prompt: "We have a system for measuring the performance of an individual person on a regular basis.",
        category: "people",
        },
    {
        prompt: "We are hiring, firing, rewarding, recognizing and compensating around our Core Values.",
        category: "people",
        },
    // Place Questions
    {
        prompt: "We have documented processes aligned with critical customer touchpoints that reinforce customer value.",
        category: "place",
        },
    {
        prompt: "Our key processes are documented and followed by our teams.",
        category: "place",
        },
    {
        prompt: "Our key processes are consistently managed, measured delivered.",
        category: "place",
        },
    {
        prompt: "We have the appropriate tools and systems to support our key processes.",
        category: "place",
        },
    {
        prompt: "Our hiring practices and training programs support our people in their ability to perform at a high level.",
        category: "place",
        },
    //Process Questions
    {
        prompt: "Our work setting reminds us of the purpose we serve.",
        category: "process",
        },
    {
        prompt: "Our systems and tools allow us to do our best work.",
        category: "process",
        },
    {
        prompt: "Our work setting favorably influences the thoughts, feelings and attitudes of our people.",
        category: "process",
        },
    {
        prompt: "Our work systems are strong and efficient.",
        category: "process",
        },
    {
        prompt: "Our work systems support us in communicating effectively.",
        category: "process",
        },
  

    ];

    export default questions;