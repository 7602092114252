import React from 'react';
import './QuestionNumbers.css';

import questions from '../../util/questions';

class QuestionNumbers extends React.Component {
    render() {
        return <h3 className='questionNumbers light'>{this.props.currentQuestionNumber + 1} / {questions.length}</h3>
    }
}


export default QuestionNumbers;


