import React from 'react';
import './FacSubmission.css';
import VennDiagramFac from '../VennDiagram/VennDiagram_fac'

class FacSubmission extends React.Component {

    constructor(props) {
        super(props)

        this.state={
          questions: false
        }

        this.getColor = this.getColor.bind(this);
      
    }

    
    

    getColor(score) {
        let color;
    
        if (score <= -15) {
          color = "#FF0000";
        } else if (score < -10) {
          color = "#FF4000";
        } else if (score < -5) {
          color = "#FF8000";
        } else if (score < 0) {
          color = "#FFBF00";
        } else if (score === 0) {
          color = "#FFFF00";
        } else if (score < 5) {
          color = "#BFE411";
        } else if (score < 10) {
          color = "#80C923";
        } else if (score < 15) {
          color = "#40AD34";
        } else if (score >= 15) {
          color = "#009245";
        }
        return color;
      }
    render() {
      const scores = [
        {
          category: 'purpose',
          score: this.props.result.Purpose
        },
        {
          category: 'people',
          score: this.props.result.People
        },
        {
          category: 'process',
          score: this.props.result.Process
        },
        {
          category: 'place',
          score: this.props.result.Place
        },
        {
          category: 'leadership',
          score: this.props.result.Leadership
        },
        {
          category: 'performance',
          score: this.props.result.Performance
        },
        {
          category: 'system',
          score: this.props.result.System
        },
        {
          category: 'setting',
          score: this.props.result.Setting
        },
        {
          category: 'climate',
          score: this.props.result.Climate
        },
        {
          category: 'hiring',
          score: this.props.result.Hiring
        },
        {
          category: 'skills',
          score: this.props.result.Skills
        },
        {
          category: 'tools',
          score: this.props.result.Tools
        },
      ]

        return (
            <div className="submission">
                <div className="userInfo">
                    <h1 className="userName">{this.props.result.name}</h1>
                    <h4 className="companyName" >{this.props.result.company}</h4>
                </div>
                {this.state.questions === false && <div>
                <VennDiagramFac finalScores={scores} />
                <div className="scores">
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Purpose)}}>Purpose: {this.props.result.Purpose.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.People)}}>People: {this.props.result.People.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Process)}}>Process: {this.props.result.Process.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Place)}}>Place: {this.props.result.Place.toFixed(2)}</span>

                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Leadership)}}>Leadership: {this.props.result.Leadership.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Performance)}}>Performance: {this.props.result.Performance.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.System)}}>System: {this.props.result.System.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Setting)}}>Setting: {this.props.result.Setting.toFixed(2)}</span>

                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Climate)}}>Climate: {this.props.result.Climate.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Hiring)}}>Hiring: {this.props.result.Hiring.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Skills)}}>Skills: {this.props.result.Skills.toFixed(2)}</span>
                    <span className="score" style={{backgroundColor: this.getColor(this.props.result.Tools)}}>Tools: {this.props.result.Tools.toFixed(2)}</span>
                </div>
                </div>}
                {this.state.questions === true && <div>

                  {this.props.result.Answers.map(question => {
                    return <div id="facQuestion">
                      <p id="facQuestionPrompt">{question.prompt}</p>
                      <span id="facQuestionScore" style={{color: this.getColor(question.score * (this.props.result.Answers.length / 4))}}>{question.score}</span>
                      </div>
                  }
                    )}
                  </div>}
                  {this.props.result.Answers && <button id="viewQuestions" onClick={() => {
                    if(this.state.questions === false) {
                      this.setState({questions: true})}
                      else {
                        this.setState({questions: false})
                      }}}>{this.state.questions ? 'Results' : 'Questions'}</button>}
                </div>
        )
    }
}

export default FacSubmission;