import React from "react";

import categories from '../../util/categories';

class VennDiagram extends React.Component {
  constructor(props) {
    super(props);
    this.updateColors = this.updateColors.bind(this);
    this.getColor = this.getColor.bind(this);
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    this.updateColors();
  }

  getColor(score) {
    let color;

    if (score <= -15) {
      color = "#FF0000";
    } else if (score < -10) {
      color = "#FF4000";
    } else if (score < -5) {
      color = "#FF8000";
    } else if (score < 0) {
      color = "#FFBF00";
    } else if (score === 0) {
      color = "#FFFF00";
    } else if (score < 5) {
      color = "#BFE411";
    } else if (score < 10) {
      color = "#80C923";
    } else if (score < 15) {
      color = "#40AD34";
    } else if (score >= 15) {
      color = "#009245";
    }
    return color;
  }

  updateColors() {
    /* outer ring */
    document.getElementById("purpose").style.fill = this.getColor(
      this.props.finalScores[0].score
    );
    document.getElementById("people").style.fill = this.getColor(
      this.props.finalScores[1].score
    );
    document.getElementById("process").style.fill = this.getColor(
      this.props.finalScores[2].score
    );
    document.getElementById("place").style.fill = this.getColor(
      this.props.finalScores[3].score
    );
    /* middle ring */
    document.getElementById("leadership").style.fill = this.getColor(
      this.props.finalScores[4].score
    );
    document.getElementById("performance").style.fill = this.getColor(
      this.props.finalScores[5].score
    );
    document.getElementById("system").style.fill = this.getColor(
      this.props.finalScores[6].score
    );
    document.getElementById("setting").style.fill = this.getColor(
      this.props.finalScores[7].score
    );

    /* inner ring */
    document.getElementById("climate").style.fill = this.getColor(
      this.props.finalScores[8].score
    );
    document.getElementById("hiring").style.fill = this.getColor(
      this.props.finalScores[9].score
    );
    document.getElementById("skill").style.fill = this.getColor(
      this.props.finalScores[10].score
    );
    document.getElementById("tools").style.fill = this.getColor(
      this.props.finalScores[11].score
    );
  }

  handleHover(category) {
    let categoryName;
    let categoryDetails;
    switch (category) {
        case 'purpose':
          categoryName = categories[0].name;
          categoryDetails = categories[0].description;
            break;

        case 'people':
          categoryName = categories[1].name;
          categoryDetails = categories[1].description;
            break;

        case 'process':
          categoryName = categories[2].name;
          categoryDetails = categories[2].description;
            break;

        case 'place':
          categoryName = categories[3].name;
          categoryDetails = categories[3].description;
            break;

        case 'leadership':
          categoryName = categories[4].name;
          categoryDetails = categories[4].description;
            break;

        case 'performance':
          categoryName = categories[5].name;
          categoryDetails = categories[5].description;
            break;

        case 'system':
          categoryName = categories[6].name;
          categoryDetails = categories[6].description;
            break;

        case 'setting':
          categoryName = categories[7].name;
          categoryDetails = categories[7].description;
            break;

        case 'climate':
          categoryName = categories[8].name;
          categoryDetails = categories[8].description;
            break;

        case 'hiring':
          categoryName = categories[9].name;
          categoryDetails = categories[9].description;
            break;

        case 'skill':
          categoryName = categories[10].name;
          categoryDetails = categories[10].description;
            break;

        case 'tools':
          categoryName = categories[11].name;
          categoryDetails = categories[11].description;
            break;

        default:
            break;
    }
    this.props.updateTooltip(categoryName, categoryDetails);
}

  render() {
    return (
      <div id="vennDiagramContainer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 520 520"
          width="250"
        >
          
          <path
            id="purpose"
            onMouseOver={() => this.handleHover('purpose')}
            onClick={() => this.handleHover('purpose')}
            className="vennSection"
            d="M255.5,148.38a150.14,150.14,0,0,1,145.18-38.06A150,150,0,0,0,111.4,110a150.2,150.2,0,0,1,144.1,38.36Z"
          />

          <path
            id="process"
            onMouseOver={() => this.handleHover('process')}
            onClick={() => this.handleHover('process')}
            className="vennSection"
            d="M255.5,361.62a150.14,150.14,0,0,1-145.18,38.06,150,150,0,0,0,289.28.3,150.2,150.2,0,0,1-144.1-38.36Z"
          />

          <path
            id="people"
            onMouseOver={() => this.handleHover('people')}
            onClick={() => this.handleHover('people')}
            className="vennSection"
            d="M511,255A150.05,150.05,0,0,0,400.68,110.32a150.14,150.14,0,0,1-38.07,145.19A150.16,150.16,0,0,1,399.6,400,150.07,150.07,0,0,0,511,255Z"
          />

          <path
            id="leadership"
            onMouseOver={() => this.handleHover('leadership')}
            onClick={() => this.handleHover('leadership')}
            className="vennSection"
            d="M255.5,148.38a149.84,149.84,0,0,1,39.18,66.94,149.93,149.93,0,0,1,67.93,40.19,150.14,150.14,0,0,0,38.07-145.19A150.14,150.14,0,0,0,255.5,148.38Z"
          />

          <path
            id="performance"
            onMouseOver={() => this.handleHover('performance')}
            onClick={() => this.handleHover('performance')}
            className="vennSection"
            d="M362.61,255.51a149.84,149.84,0,0,1-68,39.47,149.84,149.84,0,0,1-39.1,66.64A150.2,150.2,0,0,0,399.6,400a150.16,150.16,0,0,0-37-144.47Z"
          />

          <path
            id="hiring"
            onMouseOver={() => this.handleHover('hiring')}
            onClick={() => this.handleHover('hiring')}
            className="vennSection"
            d="M294.68,215.32A150.67,150.67,0,0,1,294.6,295a149.84,149.84,0,0,0,68-39.47A149.93,149.93,0,0,0,294.68,215.32Z"
          />

          <path
            id="place"
            onMouseOver={() => this.handleHover('place')}
            onClick={() => this.handleHover('place')}
            className="vennSection"
            d="M148.39,254.49A150.16,150.16,0,0,1,111.4,110a150,150,0,0,0-1.08,289.66,150.14,150.14,0,0,1,38.07-145.19Z"
          />

          <path
            id="setting"
            onMouseOver={() => this.handleHover('setting')}
            onClick={() => this.handleHover('setting')}
            className="vennSection"
            d="M148.39,254.49a149.84,149.84,0,0,1,68-39.47,149.84,149.84,0,0,1,39.1-66.64A150.2,150.2,0,0,0,111.4,110a150.16,150.16,0,0,0,37,144.47Z"
          />

          <path
            id="system"
            onMouseOver={() => this.handleHover('system')}
            onClick={() => this.handleHover('system')}
            className="vennSection"
            d="M255.5,361.62a149.84,149.84,0,0,1-39.18-66.94,149.93,149.93,0,0,1-67.93-40.19,150.14,150.14,0,0,0-38.07,145.19A150.14,150.14,0,0,0,255.5,361.62Z"
          />

          <path
            id="tools"
            onMouseOver={() => this.handleHover('tools')}
            onClick={() => this.handleHover('tools')}
            className="vennSection"
            d="M216.4,215a149.84,149.84,0,0,0-68,39.47,149.93,149.93,0,0,0,67.93,40.19A150.67,150.67,0,0,1,216.4,215Z"
          />

          <path
            id="climate"
            onMouseOver={() => this.handleHover('climate')}
            onClick={() => this.handleHover('climate')}
            className="vennSection"
            d="M294.68,215.32a149.84,149.84,0,0,0-39.18-66.94A149.84,149.84,0,0,0,216.4,215a150.77,150.77,0,0,1,78.28.3Z"
          />

          <path
            id="skill"
            onMouseOver={() => this.handleHover('skill')}
            onClick={() => this.handleHover('skill')}
            className="vennSection"
            d="M216.32,294.68a149.84,149.84,0,0,0,39.18,66.94A149.84,149.84,0,0,0,294.6,295a150.77,150.77,0,0,1-78.28-.3Z"
          />

          <path
            id="center"
            onMouseOver={() => this.handleHover('center')}
            onClick={() => this.handleHover('center')}
            className="vennSection"
            d="M294.68,215.32a150.77,150.77,0,0,0-78.28-.3,150.67,150.67,0,0,0-.08,79.66,150.77,150.77,0,0,0,78.28.3,150.67,150.67,0,0,0,.08-79.66Z"
          />
        </svg>
        
      </div>
    );
  }
}

export default VennDiagram;
